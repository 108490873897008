import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
@Injectable()
export class PageGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot):any {
    if (route.routeConfig.path == 'page-jump-fire') {
      window.open("https://b.119.net", "_blank");
    } else if (route.routeConfig.path == 'page-jump-library') {
      window.open("https://www.nimonikapp.com.cn/login", "_blank");
    } else if (route.routeConfig.path == 'page-jump-job') {
      window.open("https://cx.mem.gov.cn/special?index=0", "_blank");
    }
  }
}
