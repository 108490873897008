import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { NgxPermissionsService } from 'ngx-permissions';

import * as AuthActions from '../actions/auth';

@Injectable()
export class AuthEffects {

  login$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.Login),
      switchMap(({ credential }) => this.authService
        .login(credential)
        .pipe(
          map((response) => {
            if (response.STATUS === 0 && response.DATA) {
              return AuthActions.LoginSuccess({ auth: response.DATA });
            } else {
              return AuthActions.LoginFailure({ msg: response.MSG });
            }
          }),
          catchError((err: Error) => {
            return of(AuthActions.LoginFailure({ msg: err.message }));
          })
        )
      )
    )
  );

  loginSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.LoginSuccess),
      tap((auth) => {
        const userName = auth.auth.userName;
        localStorage.setItem('userName', userName);
        localStorage.setItem('userId', auth.auth.userId + '');
        const userRoles = auth.auth.userRoles;
        for(let item of userRoles) {
          if (item.level === 1){
            this.router.navigate(['./screen-dashboard']);
            return;
          }
        }

        const savedUrl: string = sessionStorage.getItem('savedUrl');
        if (savedUrl) {
          this.router.navigateByUrl(savedUrl);
          sessionStorage.removeItem('savedUrl');
        } else {
          this.router.navigate(['./']);
        }
      })
    ),
    { dispatch: false }
  );

  LoginWithSavedToken$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.LoginWithSavedToken),
      map((payload) => AuthActions.GetUserDetailFromToken({ token: payload.token })),
    ),
  );

  GetPermissionListAfterLogin$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.GetUserDetailFromTokenSuccess),
      map(() => AuthActions.GetUserPermissionList()),
    ),
  );

  GetUserDetailFromToken$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.GetUserDetailFromToken),
      switchMap((payload) => this.authService
        .getUserDetailFromToken()
        .pipe(
          map((response) => {
            if (response.STATUS === 0 && response.DATA) {
              return AuthActions.GetUserDetailFromTokenSuccess({ payload: response.DATA });
            } else {
              return AuthActions.GetUserDetailFromTokenFailure({ msg: response.MSG });
            }
          }),
          catchError((err: Error) => {
            return of(AuthActions.GetUserDetailFromTokenFailure({ msg: err.message }));
          })
        )
      )
    ),
  );

  GetUserPermissionList$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.GetUserPermissionList),
      switchMap(({}) => this.authService
        .getUserPermissions()
        .pipe(
          map((response) => {
            if (response.STATUS === 0 && response.DATA) {
              return AuthActions.GetUserPermissionListSuccess({ res: response.DATA });
            } else {
              return AuthActions.GetUserPermissionListFailure({ msg: response.MSG });
            }
          }),
          catchError((err: Error) => {
            return of(AuthActions.GetUserPermissionListFailure({ msg: err.message }));
          })
        )
      )
    )
  );

  GetUserDetailFromTokenFailure$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.GetUserDetailFromTokenFailure),
      map(() => AuthActions.Logout())
    )
  );

  logout$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.Logout),
      tap(() => {
        this.authService.logout(false);
        this.permissionsService.flushPermissions();
        const str = document.baseURI;
        window.location.replace('https://cloudapi.yuyuantm.com.cn/oauth2/logout?yuyuan_syscode=S20079&service=' + str);
        // this.router.navigate(['/auth/login']);

      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthenticationService,
    private permissionsService: NgxPermissionsService
  ) { }
}
