import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

const baseUrlAPI = API.baseUrl;
import { Observable } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';

const messageGroupAPI = API.messageGroup;

@Injectable()
export class MessageGroupService {
  constructor(
    private http: HttpClient
  ) { }

  getMessageGroup(id: string): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(messageGroupAPI + '/' + id);
  }

  queryMessageGroupList(query: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 1000
      },
      queryOrderBies: [
        {
          columnName: 'id',
          orderType: 'desc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('full_name', query, 'like')] : []),
    ];

    return this.http.post<ResponseModel>(messageGroupAPI + '/list', queryUtils);
  }

  getMessageGroupList(): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils();
    return this.http.post<ResponseModel>(baseUrlAPI + '/messageGroup/list', queryUtils);
  }
  private genQueryUtils(): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 1000,
      },
    };
  }

}
