// English
export const locale = {
  lang: 'en',
  data: {
    MENU: {
      TASK_DETAIL: 'Task Detail',
      TASK_CONTENT: 'Task Content',
      DASHBOARD: 'Dashboard',
      DBD110: 'Dashboard',
      DBD111: 'Dashboard Homepage',
      DBD112: 'Issue Analysis',
      DBD113: 'Inspection Correction',
      DASHBOARD_HOMEPAGE: 'Homepage',
      SYSTEM_SETUP: 'System Setup',
      TAS110: 'Personal Task Center',
      TAS111: 'View All Tasks',
      TAS112: 'Unclaimed Tasks',
      TRA100: 'Training and License',
      TRA110: 'Training Inquiries',
      TRA111: 'Curriculum',
      TRA112: 'Lesson',
      TRA113: 'Instructor',
      TRA114: 'Class Scheduling',
      TRA115: 'Training Calendar',
      TRA116: 'Exemption Utility',
      TRA117: 'Employee Training Record',
      TRA118: 'Course Record',
      TRA119: 'My Course',
      TRA120: 'Certificate Management',
      TRA121: 'Certificate Management',
      TRA122: 'Certificate Category Management',
      TRA130: 'Training Management',
      TRA131: 'Training Sign Table',
      TRA140: 'Question Bank Management',
      TRA141: 'Question Set',
      TRA142: 'Quiz Management',
      TRA143: 'Question Management',
      RIS100: 'Risk Assessment',
      RIS110: 'Risk Analysis',
      RIS111: 'Risk Matrix',
      RIS112: 'Job Risk inquiry',
      RIS120: 'Risk Control',
      RIS121: 'Planning List',
      RIS130: 'Risk Record',
      RIS131: 'Risk Assessment Record',
      RIS132: 'Correction Action',
      INC100: 'Incident Management',
      INC110: 'Accident Handling',
      INC111: 'Incident Report',
      INC112: 'Incident Inquiry',
      INC113: 'Incident Investigation',
      INC114: 'Corrective Action',
      INC115: 'Working Hours List',
      INC116: 'Working Hours Detail',
      INC117: 'Injured People',
      INC120: 'Occupation Hazard',
      INC121: 'Hazard Record',
      INC122: 'Working Day Record',
      INC123: 'Hazard Report',
      INC124: 'Injury Record Form',
      INC125: 'Message Center',
      GR100: 'Regulatory Compliance',
      GR110: 'Regulatory Database',
      GR111: 'Regulations Overview',
      GR112: 'My Favorite',
      GR113: 'Company Applicable List',
      GR114: 'Upload Regulation Updates',
      GR120: 'Identification and Correction',
      GR121: 'Compliance Assessment',
      GR122: 'Identification Task',
      GR123: 'Corrective Action',
      SS100: 'System Setting',
      SS110: 'Basic Configuration',
      SS111: 'Organization',
      SS112: 'Employee Management',
      SS113: 'Facility Management',
      SS114: 'Message Group',
      SS115: 'Announcement Management',
      SS116: 'Role Management',
      SS120: 'Advanced Settings',
      SS121: 'System Code',
      SS122: 'Role Management',
      SS130: 'Form Management',
      SS131: 'Question Bank',
      SS132: 'Forms',
      SS140: 'Space Management',
      SS141: 'Space Management',
      SS142: 'Space Type Management',
      SS143: 'Space Group Management',
      INS100: 'Inspection',
      INS110: 'Inspection Activity',
      INS111: 'Issue Reporting',
      INS112: 'Inspection Scheduling',
      INS113: 'Ad-hoc Inspection',
      INS120: 'Inspection Result',
      INS121: 'Inspection Record',
      INS122: 'Issue list',
      INS123: 'Corrective Action',
      INS124: 'Issue Correction',
      INS125: 'Report Record',
      NM100: 'Notification',
      NM111: 'Message Center',
      CFM100: 'Compliance File',
      CFM110: 'Compliance File',
      CFM111: 'Compliance File Management',
      CFM112: 'Compliance File List',
      CFM113: 'Compliance File Category',
      GI100: 'Gov. Inspection',
      GI110: 'Gov. Inspection',
      GI111: 'Gov. Inspection',
      GI112: 'Gov. Penalty',
      INS130: 'Third Party Inspection',
      INS131: 'Third Party Inspection Reporting',
      INS132: 'Third Party Inspection Inquiry',
      INS133: 'Government Penalty',
      INS134: 'Third Party Inspection Correction',
      DOC100: 'Documentation Management',
      DOC110: 'Documentation Management',
      DOC111: 'Documentation Management',
      DOC112: 'Documentation List',
      DOC113: 'Documentation Classification',
      CEM100: 'Carbon Emission Management',
      CEM110: 'Data Filling',
      CEM111: 'ESG Filling',
      CEM112: 'ESG Filling Records',
      CEM113: 'ESG Historical Data Changes',
      CEM114: 'ESG Not Filled In By Unit',
      CEM120: 'Carbon Check Management',
      CEM121: 'Carbon Check',
      CEM122: 'Carbon Check Records',
      CEM130: 'Emission Reduction Management',
      CEM131: 'Emission Reduction Plan',
      CEM132: 'Emission Reduction Method',
      CEM140: 'Basic Configuration',
      CEM141: 'GHG Checklist',
      CEM142: 'GHG Source',
      CEM143: 'GHG Coefficient',
      CEM144: 'ESG Quota',
      CEM145: 'API Management',
      CEM150: 'Report',
      CEM151: 'ESG Report',
      SCREEN_DASHBOARD: 'Large Screen Dashboard',
      EHSQ_LIBRARY: 'EHSQ Regulatory Library',
      EHSQ_FIRE_FIGHTING: 'Fire Internet of Things',
      SPECIAL_JOB_CERTIFICATE: 'Special Job Certificate Inquiry'
    },
    GENERAL: {
      ACTIONS: {
        LOGOUT: 'Logout',
        OK: 'OK',
        CLEAR: 'Clear',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        LEAVE: 'Leave',
        SUBMIT: 'Submit',
        RESET: 'Reset',
        SEARCH: 'Search',
        VIEW: 'View',
        ADD: 'Add',
        EDIT: 'Edit',
        EXPORT: 'Export',
        EXPORT_ALL: 'Export All',
        DELETE: 'Delete',
        UPDATE: 'Update',
        SAVE: 'Save',
        SAVE_SUBMIT: 'Save & Submit',
        DO_NOT_SAVE: 'Do Not Save',
        SAVE_CONFIRM: 'Save and Confirm',
        SAVE_LEAVE: 'Save and Leave',
        BACK: 'Back',
        PREVIOUS: 'Previous',
        NEXT: 'Next',
        NO: 'No',
        YES: 'Yes',
        CHOOSE: 'Choose',
        CLEAR_SELECTION: 'None',
        BACK_TO_LIST: 'Back To List',
        PUBLISH: 'Publish',
        ADD_NEW_VERSION: 'Add New Version',
        ADVANCED_SEARCH_SHOW: 'Show Advanced Search',
        ADVANCED_SEARCH_HIDE: 'Hide Advanced Search',
        SUBMIT_PREVIEW: 'Submit Preview',
        ASSIGN: 'Assign',
        CLAIM: 'Claim',
        EXPORT_EXCEL: 'Export Excel',
        DRAFT: 'Save As Draft',
        CLOSE_CASE: 'Close Case',
        REJECT: 'Reject',
      },
      FORM_REGION: 'Region',
      FORM_SECTION: 'Section',
      NO_ATTACHMENTS: 'Attachment(s) is required!',
      TITLE: {
        DELETE: 'Delete',
        LEAVE: 'Leave site?',
        SURE: 'Clear report?'
      },
      MSG: {
        DELETE: 'Record(s) will be deleted and cannot undo. Are you sure to continue?',
        CONFIRM_LEAVE: 'There might be unsaved content. Are you sure to leave?',
        LEAVE: 'Changes you made may not be saved.',
        SURE: 'Are you sure to clear the report?',
        UNFILLED_FIELD: 'Unable to save, there are unfilled fields.',
        UNFILLED_FIELD_SUBMIT: 'Unable to submit, there are unfilled fields.',
        FIELD_EMPTY: 'Please filled out all necessary fileds',
        CREATE_SUCCESS: 'Field/Option created',
        UPDATE_SUCCESS: 'Field/Option saved',
        DELETE_SUCCESS: 'Field/Option deleted',
        SAVE_SUCCESS: 'Save Succeeded',
        SUBMIT_SUCCESS: 'Submit Succeeded',
        SEARCH_NECESSARY: 'Filter options that are required have not been filled in!'
      },
      NONE: 'None',
      NO_RESULT: 'No result',
      PLACEHOLDER: {
        SEARCH: 'Enter to search',
        SELECT: 'Choose an option',
        CLARIFY: 'Please explain',
        INPUT: 'Please input'
      },
      SELECT_REQUIRED: 'Please select an item',
      CHILDREN_ORG_INCLUDED: 'Children Organization Included'
    },
    LABEL: {
      CHECKBOX_SELECTED: 'selected'
    },
    DX: {
      NO_DATA_TEXT: 'No data',
      PAGER: {
        INFOTEXT: 'Page {0} of {1} ({2} items)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: 'Select File',
        LABEL_TEXT: 'or Drop file here',
        READY_MSG: 'Ready to upload',
        MAX_FILE_SIZE: 'Maximum file size',
        INVALID_MAX_FILE_SIZE_MSG: 'File is too large',
        IGNORE_OVERLARGE_FILE_HINT: 'Overlarge files won\'t be uploaded',
        RESET: 'Clear Selected File',
        DELETE: 'Delete File',
        CONFIRM_DELETE: 'Are you sure to delete this file?',
        FAILED_TO_UPLOAD: 'Failed to upload',
        IMPORT: 'Import',
        PICTURE_HINT: 'Please upload picture file with suffix of jpg, jpeg, gif or png. Picture file size under 2M is recommended.',
        PIC_TIP: 'The file you uploaded is too small, which may cause the image to be blurry.',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: 'Items per page',
        FIRST: 'First page',
        LAST: 'Last page',
        PREVIOUS: 'Previous page',
        NEXT: 'Next page',
      },
    },
    HTTP_ERROR_MSG: {
      '401': 'Authentication failed. Please login',
      '404': 'System is offline or under maintainence. Please try again later',
      '413': 'Uploaded file was too large. Please try smaller file'
    }
  }
};
