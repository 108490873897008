// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {AuthGuard} from '@quantum-theme/core/guards/auth.guard';
import {PageGuard} from '@quantum-theme/core/guards/page.guard';

const routes: Routes = [
	// {path: '', redirectTo: 'quantum', pathMatch: 'full'},
  {path: '', loadChildren: 'app/yuyuan-login/yuyuan-login.module#YuyuanLoginModule'},
  {path: 'task-center', loadChildren: 'app/task-center/task-center.module#TaskCenterModule'},
  {path: 'co2-data-list', loadChildren: 'app/co2-data-list/co2-data-list.module#CO2DataListAutoLoginModule'},
  {path: 'doc-list', loadChildren: 'app/doc-list/doc-list.module#DocListAutoLoginModule'},
  {path: 'scheduled-inspection-task', loadChildren: 'app/scheduled-inspection-task/scheduled-inspection-task.module#ScheduledInspectionTaskAutoLoginModule'},
  {path: 'inspection-issue', loadChildren: 'app/inspection-issue/inspection-issue.module#InspectionIssueAutoLoginModule'},
  {path: 'corrective-action', loadChildren: 'app/corrective-action/corrective-action.module#CorrectiveActionAutoLoginModule'},
  {path: 'doc-management', loadChildren: 'app/doc-management/doc-management.module#DocManagementAutoLoginModule'},
  // {path: 'data-record', loadChildren: 'app/ehs/carbon-emission/data-filling/co2-data-filling-record/co2-data-filling-record.module#CO2DataFillingRecordModule'},
  {path: 'data-change', loadChildren: 'app/ehs/carbon-emission/data-filling/co2-historical-data-change/co2-historical-data-change.module#CO2HistoricalDataChangeModule'},
  {path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule'},
  {
    path: 'quantum',
    canActivate: [AuthGuard],
    loadChildren: 'app/quantum-theme/views/themes/quantum/theme.module#ThemeModule'
  },
  {
    path: 'page-jump-library',
    canActivate: [PageGuard],
    loadChildren: 'app/screen-dashboard/screen-dashboard.module#LargeScreenDashboardModule'
  },
  {
    path: 'page-jump-fire',
    canActivate: [PageGuard],
    loadChildren: 'app/screen-dashboard/screen-dashboard.module#LargeScreenDashboardModule'
  },
  {
    path: 'page-jump-job',
    canActivate: [PageGuard],
    loadChildren: 'app/screen-dashboard/screen-dashboard.module#LargeScreenDashboardModule'
  },
  {path: 'share', loadChildren: 'app/share-inspection/share-inspection.module#ShareInspectionModule'},
  {path: 'screen-dashboard', loadChildren: 'app/screen-dashboard/screen-dashboard.module#LargeScreenDashboardModule'},
	{path: '**', redirectTo: 'quantum/error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 108], // cool option, or ideal option when you have a fixed header on top.
    })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
