// Traditional Chinese
export const locale = {
  lang: 'zh-Hant',
  data: {
    MENU: {
      TASK_DETAIL: '任務詳情',
      TASK_CONTENT: '任務內容',
      DBD110: '报表',
      DBD111: '首页',
      DBD112: '隐患分析',
      DBD113: '检查整改分析',
      DASHBOARD_HOMEPAGE: '绩效',
      SYSTEM_SETUP: '系統設定',
      TAS110: '個人任務中心',
      TAS111: '任務管理',
      TAS112: '待認領任務',
      TRA100: '培訓與證件',
      TRA110: '培訓查詢',
      TRA111: '學程管理',
      TRA112: '課程管理',
      TRA113: '教師管理',
      TRA114: '班級排課',
      TRA115: '培訓日曆',
      TRA116: '豁免工具',
      TRA117: '员工紀錄',
      TRA118: '課程記錄',
      TRA119: '我的课程',
      TRA120: '證照管理',
      TRA121: '證照登錄',
      TRA122: '證照類別',
      TRA130: '培訓管理',
      TRA131: '培训签到表',
      TRA140: '题库管理',
      TRA141: '试题集管理',
      TRA142: '试卷管理',
      TRA143: '试题管理',
      RIS100: '風險評估',
      RIS110: '風險分析',
      RIS111: '風險矩陣',
      RIS112: '作業風險查詢',
      RIS120: '風險控制',
      RIS121: '風險評估排程',
      RIS130: '風險紀錄',
      RIS131: '風險評估任務',
      RIS132: '矯正整改',
      INC100: '事故管理',
      INC110: '事故處理',
      INC111: '事故通報',
      INC112: '事故查詢',
      INC113: '事故調查',
      INC114: '矯正整改',
      INC115: '工时列表',
      INC116: '工时详情',
      INC117: '受傷人員',
      INC120: '職災統計',
      INC121: '職災紀錄填報',
      INC122: '法定工作日紀錄',
      INC123: '職災統計報表',
      INC124: '職災紀錄填報表單',
      INC125: '消息中心',
      GR100: '法規鑑別',
      GR110: '法規資料庫',
      GR111: '法規總覽',
      GR112: '我的最愛',
      GR113: '公司適用清單',
      GR114: '上傳更新法規',
      GR120: '鑑別與整改',
      GR121: '查核活動',
      GR122: '鑑別任務',
      GR123: '矯正整改',
      SS100: '系統設定',
      SS110: '基本設定',
      SS111: '組織架構',
      SS112: '員工管理',
      SS113: '廠區管理',
      SS114: '消息群組',
      SS115: '公告管理',
      SS116: '角色管理',
      SS120: '進階設定',
      SS121: '系統代碼',
      SS122: '角色管理',
      SS130: '表格管理',
      SS131: '題庫',
      SS132: '表格',
      SS140: '空间管理',
      SS141: '空间管理',
      SS142: '空间类型管理',
      SS143: '空间群组管理',
      INS100: '檢查管理',
      INS110: '檢查活動',
      INS111: '巡檢回報',
      INS112: '檢查排程',
      INS113: '立即檢查',
      INS120: '檢查結果',
      INS121: '檢查查询',
      INS122: '缺失發現',
      INS123: '矯正改善',
      INS124: '隐患整改查询',
      INS125: '隐患查询',
      NM100: '通知管理',
      NM111: '消息中心',
      CFM100: '合规文件管理',
      CFM110: '合规文件管理',
      CFM111: '合规文件管理',
      CFM112: '合规文件清单',
      CFM113: '合规文件分类',
      GI100: '第三方检查',
      GI110: '第三方检查',
      GI111: '第三方检查',
      GI112: '政府处罚',
      INS130: '第三方檢查',
      INS131: '第三方檢查上報',
      INS132: '第三方檢查查詢',
      INS133: '政府處罰',
      INS134: '第三方檢查整改查詢',
      DOC100: '合規文件管理',
      DOC110: '合規文件管理',
      DOC111: '合規文件管理',
      DOC112: '合規文件清單',
      DOC113: '合規文件分類',
      CEM100: '碳排放管理',
      CEM110: '數據填報',
      CEM111: 'ESG填報',
      CEM112: 'ESG填報記錄',
      CEM113: 'ESG曆史數據變更',
      CEM114: 'ESG未填報單位',
      CEM120: '碳盤查管理',
      CEM121: '碳盤查',
      CEM122: '碳盤查記錄',
      CEM130: '減排管理',
      CEM131: '減排計劃',
      CEM132: '減排措施',
      CEM140: '基礎配置',
      CEM141: 'GHG清單',
      CEM142: 'GHG源',
      CEM143: 'GHG系數',
      CEM144: 'ESG指標',
      CEM145: '接口管理',
      CEM150: '報表',
      CEM151: 'ESG報表',
      SCREEN_DASHBOARD: '大屏報表',
      EHSQ_LIBRARY: 'EHSQ法規庫',
      EHSQ_FIRE_FIGHTING: '消防物联网',
      SPECIAL_JOB_CERTIFICATE: '特殊工種證件査詢'
    },
    GENERAL: {
      ACTIONS: {
        LOGOUT: '登出',
        OK: '確認',
        CANCEL: '取消',
        CLOSE: '關閉',
        LEAVE: '離開',
        SUBMIT: '提交',
        RESET: '重設',
        SEARCH: '搜尋',
        VIEW: '檢視',
        ADD: '新增',
        EDIT: '編輯',
        EXPORT_ALL: '全部匯出',
        EXPORT: '匯出',
        DELETE: '刪除',
        UPDATE: '更新',
        SAVE: '儲存',
        DO_NOT_SAVE: '不儲存',
        SAVE_CONFIRM: '儲存並確認',
        SAVE_LEAVE: '儲存並離開',
        SAVE_SUBMIT: '儲存並提交',
        BACK: '返回',
        PREVIOUS: '上一步',
        NEXT: '下一步',
        NO: '否',
        YES: '是',
        CLEAR_SELECTION: '無',
        BACK_TO_LIST: '回列表頁',
        PUBLISH: '發布',
        CHOOSE: '選擇',
        CLEAR: '清除',
        ADD_NEW_VERSION: '發布',
        ADVANCED_SEARCH_SHOW: '顯示進階搜尋',
        ADVANCED_SEARCH_HIDE: '隱藏進階搜尋',
        SUBMIT_PREVIEW: '提交预览',
        ASSIGN: '轉派',
        CLAIM: '認領',
        EXPORT_EXCEL: '导出Excel',
        DRAFT: '保存草稿',
        CLOSE_CASE: '结案',
        REJECT: '駁回',
      },
      NO_ATTACHMENTS: '请添加附件！',
      FORM_REGION: '大項',
      FORM_SECTION: '分區',
      TITLE: {
        DELETE: '確認刪除',
        LEAVE: '確認離開?',
        SURE: '確認清除回報'
      },
      MSG: {
        DELETE: '刪除後紀錄將會清空且無法復原，確定要刪除？',
        LEAVE: '您尚未儲存此筆資料，請問您確定離開？',
        CONFIRM_LEAVE: '当前页面可能存在未保存内容，您确定离开吗？',
        SURE: '確認清除事故回報嗎？',
        UNFILLED_FIELD: '無法儲存，有尚未填寫的欄位。',
        FIELD_EMPTY: '必填項未填寫，請補全',
        CREATE_SUCCESS: '添加成功',
        UPDATE_SUCCESS: '更新成功',
        DELETE_SUCCESS: '删除成功',
        SAVE_SUCCESS: '保存成功',
        SUBMIT_SUCCESS: '提交成功',
        SEARCH_NECESSARY: '必填的搜索项未选择，无法搜索！'
      },
      NO_RESULT: '沒有結果',
      NONE: '無',
      PLACEHOLDER: {
        SEARCH: '輸入以搜尋',
        SELECT: '請選擇',
        CLARIFY: '請說明',
        INPUT: '请输入'
      },
      SELECT_REQUIRED: '請選擇一項',
      CHILDREN_ORG_INCLUDED: '包含子架構'
    },
    LABEL: {
      CHECKBOX_SELECTED: '已選擇'
    },
    DX: {
      NO_DATA_TEXT: '無資料',
      PAGER: {
        INFOTEXT: '第 {0} 頁，共 {1} 頁 (共 {2} 項)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: '選擇檔案',
        LABEL_TEXT: '或拖曳檔案至此',
        READY_MSG: '準備上傳',
        MAX_FILE_SIZE: '檔案大小限制',
        INVALID_MAX_FILE_SIZE_MSG: '檔案過大',
        IGNORE_OVERLARGE_FILE_HINT: '過大的檔案將不會上傳',
        RESET: '清除已選檔案',
        DELETE: '刪除檔案',
        CONFIRM_DELETE: '您確定要刪除此檔案嗎？',
        FAILED_TO_UPLOAD: '未能成功上傳',
        IMPORT: '導入',
        PICTURE_HINT: '請上傳jpg, jpeg, gif, png格式的圖片,建議圖片大小不超過2M',
        PIC_TIP: '您上傳的文件過小，可能會導致圖片模糊',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: '每頁',
        FIRST: '第一頁',
        LAST: '最後一頁',
        PREVIOUS: '上一頁',
        NEXT: '下一頁',
      },
    },
    HTTP_ERROR_MSG: {
      '401': '认证失效，请重新登录',
      '404': '系統下線或維護中，請稍候再試',
      '413': '上傳檔案過大，請嘗試較小檔案'
    }
  }
};
