import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '@_core/models/response';
import { API } from '@quantum-theme/core/_config/api/api';

@Injectable()
export class FileService {
  constructor(
    private http: HttpClient
  ) { }

  uploadFile(file): Observable<ResponseModel> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<ResponseModel>(API.file, formData);
  }

  uploadInspectionReportFile(file): Observable<ResponseModel> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<ResponseModel>(API.government + '/inspection/pdf2Excel', formData);
  }

  // 获取视频上传凭证
  uploadAliyunFile(fileName): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(API.aliyun + '/uploadCertificate/' + fileName);
  }

  // 获取上传后的url
  getAliyunFileUrl(videoId): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(API.aliyun + '/playInfo/' + videoId);
  }
}
