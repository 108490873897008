import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, tap, filter, first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { API } from '@quantum-theme/core/_config/api/api';
import { ResponseModel } from '@_core/models/response';
import { Router, ResolveEnd } from '@angular/router';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

@Injectable()
export class SystemCodeService {
  storageUpdate$ = new Subject<void>();
  private codeStorage = new Map();
  private queryQueue = new Set();

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private router: Router
  ) {
    /** When backend server is restarted, user token fails and would get HTTP 401 in following XHR then logged out.
    There will be unfinished code queue. Re-fetch them while any navigations resolved */

    this.router.events.pipe(
      filter(event => event instanceof ResolveEnd),
    ).subscribe(() => {
      if (this.queryQueue.size > 0) {
        this.loadCodes([]).pipe(first()).subscribe(() => { });
      }
    });
  }

  /** get system code without childCodes */
  getCode(systemCode: string): Observable<ResponseModel> {
    const params = new HttpParams().set('code', systemCode);
    return this.http.get<ResponseModel>(API.code, { params: params });
  }

  /** get childCodes */
  getChildren(systemCode: string): Observable<ResponseModel> {
    const params = new HttpParams().set('code', systemCode);
    return this.http.get<ResponseModel>(API.code + '/children', { params: params });
  }

  /** get code with first level childCodes */
  getFirstLevelChildren(systemCode: string): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(API.code + '/' + systemCode + '/firstLevelChildren');
  }

  /** get childCodes of multiple parent codes */
  getChildrenOf(systemCode: string | string[], observe: any = 'body'): Observable<any> {
    if (!Array.isArray(systemCode)) {
      systemCode = [systemCode];
    }
    const params = new HttpParams().set('codes', systemCode.join(','));
    return this.http.get(API.code + '/childrenOf', {
      observe: observe,
      params: params
    });
  }

  /** Load initial system codes for dropdown/options rendering and translating.
      Results will be stored(cached) for future fetching.
  */
  loadCodes(systemCode: string | string[]): Observable<any> {
    const systemCodes = Array.isArray(systemCode) ? systemCode : [systemCode];
    const newCodes = systemCodes.filter(code => code && !this.codeStorage.has(code));
    for (const code of newCodes) {
      this.queryQueue.add(code);
    }
    if (this.queryQueue.size > 0) {
      return this.getChildrenOf([...this.queryQueue] as string[], 'response')
        .pipe(
          filter(res => res.status === 200 && res.body.STATUS === 0),
          map(res => res.body),
          tap(body => {
            body.DATA.forEach(item => {
              this.codeStorage.set(item.code, item);
              if (this.queryQueue.has(item.code)) {
                this.queryQueue.delete(item.code);
              }
              /** flatten child and save to storage*/
              this.loopChildren(item);
            });
            this.storageUpdate$.next();
          }),
          map(() => this.assembleResult(systemCodes)),
        );
    } else {
      return of(this.assembleResult(systemCodes));
    }
  }

  private loopChildren(item: any): void {
    if (item.childCodes && item.childCodes.length) {
      item.childCodes
        .filter(child => child.code && !this.codeStorage.has(child.code))
        .forEach(child => {
          this.codeStorage.set(child.code, child);
          this.loopChildren(child);
        });
    }
  }

  private assembleResult(systemCodes: string[]): any {
    const result = {};
    systemCodes.forEach(code => {
      result[code] = this.codeStorage.get(code);
    });
    return result;
  }

  translate(code: string | any): string {
    const locale = this.translateService.currentLang;
    const keyMap = new Map([
      ['en', 'en'],
      ['zh-Hant', 'tr'],
      ['zh-Hans', 'zh'],
    ]);
    const localeKey = keyMap.get(locale) || 'en';
    if (this.codeStorage.has(code)) { // string
      return this.codeStorage.get(code)[`code_desc_${localeKey}`];
    } else if (typeof code === 'object') {
      return '[' + code.code + '] ' + code[`code_desc_${localeKey}`];
    } else {
      return code;
    }
  }

  translateStream(code: string | any): Observable<string> {
    return this.storageUpdate$.pipe(
      map(() => this.translate(code))
    );
  }

  getCodeStorage(): any {
    return this.codeStorage
  }


}
