/** --- Quantum Core Module ---
 * Imported in root(AppModule) as an Eager Loading module, which provides singleton services for whole site.
 */

import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { InterceptService } from './services/intercept.service';
import { SystemCodeService } from './services/system-code.service';
import { EmployeeService } from './services/employee.service';
import { CorporateService } from './services/corporate.service';
import { FileService } from './services/file.service';
import { FacilityService } from './services/facility.service';
import { JobService } from './services/job.service';
import { RoleService } from './services/role.service';
import { RegulationService } from './services/regulation.service';
import { FormManagementService } from './services/form-management.service';
import { MessageGroupService } from './services/message-group.service';
import { LessonService } from './services/lesson.service';
import { MatPaginatorIntl } from '@angular/material';
import { MatPaginatorI18nService } from './services/mat-paginator-i18n.service';
@NgModule({
  imports: [
    HttpClientModule,
    MatMomentDateModule
  ],
  providers: [
    EmployeeService,
    CorporateService,
    SystemCodeService,
    FileService,
    FacilityService,
    JobService,
    RoleService,
    LessonService,
    RegulationService,
    MessageGroupService,
    FormManagementService,
    MessageGroupService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    /** mat-datepicker custom format using moment.js.
     * See https://material.angular.io/components/datepicker/overview#customizing-the-parse-and-display-formats
     */
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD'
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'YYYY MMM',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY MMM'
        }
      }
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    }
  ]
})
export class QuantumCoreModule {
  constructor(
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
  ) {
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      const langMap = new Map([
        ['zh-Hant', 'zh-TW'],
        ['zh-Hans', 'zh-CN'],
        ['en', 'en']
      ]);
      this.adapter.setLocale(langMap.get(params.lang) || 'en');
    });
  }
}
