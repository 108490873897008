import { createAction, props } from '@ngrx/store';
import { Credential } from '@auth/models/credential';
import { AccessData } from '@auth/models/access-data';

export const Login = createAction(
  '[Auth] Login',
  props<{ credential: Credential }>()
);

export const Logout = createAction(
  '[Auth] Logout'
);

export const LoginSuccess = createAction(
  '[Auth] Login Success',
  props<{ auth: AccessData['DATA'] }>()
);

export const LoginFailure = createAction(
  '[Auth] Login Failure',
  props<{ msg: string }>()
);

export const LoginWithSavedToken = createAction(
  '[Auth] Login with saved token',
  props<{ token: string }>()
);

export const GetUserDetailFromToken = createAction(
  '[Auth] Get User Detail From Token',
  props<{ token: string }>()
);

export const GetUserDetailFromTokenSuccess = createAction(
  '[Auth] Get User Detail From Token Success',
  props<{ payload: any }>()
);

export const GetUserDetailFromTokenFailure = createAction(
  '[Auth] Get User Detail From Token Failure',
  props<{ msg: string }>()
);

// get user permission list
export const GetUserPermissionList = createAction(
  '[Auth] Get User Permission List'
);

export const GetUserPermissionListSuccess = createAction(
  '[Auth] Get User Permission List Success',
  props<{ res: any }>()
);

export const GetUserPermissionListFailure = createAction(
  '[Auth] Get User Permission List Failure',
  props<{ msg: string }>()
);
