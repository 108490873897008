import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { Observable, of } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

const baseUrlAPI = API.baseUrl;

@Injectable()
export class JobService {
  constructor(
    private http: HttpClient,
  ) { }

  getJobList(query?): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils();
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('job_name', query, 'like', 'and')] : []),
    ];  
    return this.http.post<ResponseModel>(baseUrlAPI + '/jobs/list', queryUtils);
  }

  private genQueryUtils(): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 1000,
      },
    };
  }
}
