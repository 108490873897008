// Simplified Chinese
export const locale = {
  lang: 'zh-Hans',
  data: {
    MENU: {
      TASK_DETAIL: '任务详情',
      TASK_CONTENT: '任务内容',
      DASHBOARD: '报表',
      DBD110: '报表查看',
      DBD111: '主页',
      DBD112: '隐患分析',
      DBD113: '检查整改分析',
      DASHBOARD_HOMEPAGE: '绩效',
      SYSTEM_SETUP: '系统设定',
      TAS110: '个人任务中心',
      TAS111: '任务管理',
      TAS112: '待认领任务',
      TRA100: '培训与证件',
      TRA110: '培训查询',
      TRA111: '学程管理',
      TRA112: '课程管理',
      TRA113: '教师管理',
      TRA114: '班级排课',
      TRA115: '培训日历',
      TRA116: '豁免工具',
      TRA117: '员工记录',
      TRA118: '课程记录',
      TRA119: '我的课程',
      TRA120: '证件管理',
      TRA121: '证件查询与录入',
      TRA122: '证件名称',
      TRA130: '培训管理',
      TRA131: '培训签到表',
      TRA140: '题库管理',
      TRA141: '试题集管理',
      TRA142: '试卷管理',
      TRA143: '试题管理',
      RIS100: '风险评估',
      RIS110: '风险分析',
      RIS111: '风险矩阵',
      RIS112: '作业风险查询',
      RIS120: '风险控制',
      RIS121: '风险评估计划',
      RIS130: '风险记录',
      RIS131: '风险评估任务',
      RIS132: '整改措施',
      INC100: '事故管理',
      INC110: '事故处理',
      INC111: '事故快报',
      INC112: '事故查询',
      INC113: '事故调查',
      INC114: '整改措施',
      INC115: '工时列表',
      INC116: '工时详情',
      INC117: '受伤人员',
      INC120: '职灾统计',
      INC121: '职灾记录填报',
      INC122: '法定工作日记录',
      INC123: '职灾统计报表',
      INC124: '职灾记录填报表单',
      INC125: '消息中心',
      GR100: '法律法规',
      GR110: '法规资料库',
      GR111: '法规总览',
      GR112: '我的最爱',
      GR113: '公司适用清单',
      GR114: '上传更新法规',
      GR120: '鉴别与整改',
      GR121: '查核活动',
      GR122: '鉴别任务',
      GR123: '整改措施',
      SS100: '系统设定',
      SS110: '人员架构管理',
      SS111: '组织架构',
      SS112: '员工管理',
      SS113: '工厂管理',
      SS114: '消息群组',
      SS115: '公告管理',
      SS116: '角色管理',
      SS120: '进阶管理',
      SS121: '系统代码',
      SS122: '角色管理',
      SS130: '表格管理',
      SS131: '题库',
      SS132: '表格',
      SS140: '空间管理',
      SS141: '空间管理',
      SS142: '空间类型管理',
      SS143: '空间群组管理',
      INS100: '检查与隐患',
      INS110: '检查管理',
      INS111: '隐患上报',
      INS112: '计划检查',
      INS113: '立即检查',
      INS120: '隐患管理',
      INS121: '检查结果查询',
      INS122: '隐患查询',
      INS123: '整改措施查询',
      INS124: '隐患整改查询',
      INS125: '隐患查询',
      NM100: '通知管理',
      NM111: '消息中心',
      CFM100: '合规文件管理',
      CFM110: '合规文件管理',
      CFM111: '合规文件管理',
      CFM112: '合规文件清单',
      CFM113: '合规文件分类',
      GI100: '第三方检查',
      GI110: '第三方检查',
      GI111: '第三方检查',
      GI112: '政府处罚',
      // INS120: '检查结果',
      // INS121: '检查纪录',
      // INS122: '隐患列表',
      // INS123: '整改措施',
      INS130: '第三方检查',
      INS131: '第三方检查上报',
      INS132: '第三方检查查询',
      INS133: '政府处罚',
      INS134: '第三方检查整改查询',
      DOC100: '合规文件管理',
      DOC110: '合规文件管理',
      DOC111: '合规文件管理',
      DOC112: '合规文件清单',
      DOC113: '合规文件分类',
      CEM100: '碳排放管理',
      CEM110: '数据填报',
      CEM111: 'ESG填报',
      CEM112: 'ESG填报记录',
      CEM113: 'ESG历史数据变更',
      CEM114: 'ESG未填报单位',
      CEM120: '碳盘查管理',
      CEM121: '碳盘查',
      CEM122: '碳盘查记录',
      CEM130: '减排管理',
      CEM131: '减排计划',
      CEM132: '减排措施',
      CEM140: '基础配置',
      CEM141: 'GHG清单',
      CEM142: 'GHG源',
      CEM143: 'GHG系数',
      CEM144: 'ESG指标',
      CEM145: '接口管理',
      CEM150: '报表',
      CEM151: 'ESG报表',
      SCREEN_DASHBOARD: '大屏报表',
      EHSQ_LIBRARY: 'EHSQ法规库',
      EHSQ_FIRE_FIGHTING: '消防物联网',
      SPECIAL_JOB_CERTIFICATE: '特殊工种证件查询'
    },
    GENERAL: {
      ACTIONS: {
        LOGOUT: '注销',
        OK: '确认',
        CANCEL: '取消',
        CLOSE: '关闭',
        LEAVE: '离开',
        SUBMIT: '提交',
        RESET: '重置',
        SEARCH: '搜索',
        VIEW: '查看',
        ADD: '新增',
        EDIT: '编辑',
        EXPORT_ALL: '全部导出',
        EXPORT: '导出',
        DELETE: '删除',
        UPDATE: '更新',
        SAVE: '保存',
        DO_NOT_SAVE: '不保存',
        SAVE_CONFIRM: '保存并确认',
        SAVE_SUBMIT: '保存并提交',
        SAVE_LEAVE: '保存并离开',
        BACK: '返回',
        PREVIOUS: '上一步',
        NEXT: '下一步',
        NO: '否',
        YES: '是',
        CLEAR_SELECTION: '无',
        BACK_TO_LIST: '回列表页',
        PUBLISH: '发布',
        CHOOSE: '选择',
        CLEAR: '清除',
        ADD_NEW_VERSION: '发布',
        ADVANCED_SEARCH_SHOW: '显示高级搜索',
        ADVANCED_SEARCH_HIDE: '隐藏高级搜索',
        SUBMIT_PREVIEW: '提交预览',
        CLAIM: '认领',
        ASSIGN: '转派',
        EXPORT_EXCEL: '导出Excel',
        DRAFT: '保存草稿',
        CLOSE_CASE: '结案',
        REJECT: '驳回',
      },
      TITLE: {
        DELETE: '确认删除',
        LEAVE: '确认离开?',
        SURE: '确认清除回报'
      },
      MSG: {
        DELETE: '删除后记录将会清空且无法复原，确定要删除？ ',
        LEAVE: '您尚未保存当前内容，请问您确定离开？ ',
        CONFIRM_LEAVE: '当前页面可能存在未保存内容，您确定离开吗？',
        SURE: '确认清空事故回报吗？ ',
        UNFILLED_FIELD: '无法保存，有尚未填写的输入框',
        UNFILLED_FIELD_SUBMIT: '无法提交，有尚未填写的输入框',
        FIELD_EMPTY: '必填项未填写，请补全',
        CREATE_SUCCESS: '添加成功',
        UPDATE_SUCCESS: '更新成功',
        DELETE_SUCCESS: '删除成功',
        SAVE_SUCCESS: '保存成功',
        SUBMIT_SUCCESS: '提交成功',
        SEARCH_NECESSARY: '必填的搜索项未选择，无法搜索！'
      },
      NONE: '无',
      NO_RESULT: '没有结果',
      PLACEHOLDER: {
        SEARCH: '输入以搜索',
        SELECT: '请选择',
        CLARIFY: '请说明',
        INPUT: '请输入'
      },
      SELECT_REQUIRED: '请选择一项',
      FORM_REGION: '大项',
      FORM_SECTION: '分区',
      NO_ATTACHMENTS: '请添加附件！',
      CHILDREN_ORG_INCLUDED: '包含子架构'
    },
    LABEL: {
      CHECKBOX_SELECTED: '已选择'
    },
    DX: {
      NO_DATA_TEXT: '无数据',
      PAGER: {
        INFOTEXT: '第 {0} 页，共 {1} 页 (共 {2} 项)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: '选择附件',
        LABEL_TEXT: '或拖动附件至此',
        READY_MSG: '准备上传',
        MAX_FILE_SIZE: '附件大小限制',
        INVALID_MAX_FILE_SIZE_MSG: '附件过大',
        IGNORE_OVERLARGE_FILE_HINT: '过大的附件将不会上传',
        RESET: '清除已选附件',
        DELETE: '删除附件',
        CONFIRM_DELETE: '您确定要删除此附件吗？ ',
        FAILED_TO_UPLOAD: '未能成功上传',
        IMPORT: '导入',
        PICTURE_HINT: '请上传jpg, jpeg, gif, png格式的图片,建议图片大小不超过2M',
        PIC_TIP: '您上传的文件过小，可能会导致图片模糊',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: '每页',
        FIRST: '第一页',
        LAST: '最后一页',
        PREVIOUS: '上一页',
        NEXT: '下一页',
      },
    },
    HTTP_ERROR_MSG: {
      '401': '认证失效，请重新登陆',
      '404': '系统下线或维护中，请稍候再试',
      '413': '上传附件过大，请尝试较小附件'
    }
  }
};
